
.comingsoon-container {
    min-height: 90vh;
    background-color: #ffffff;
    display: table;
    width: 100%;
}


.comingsoon-hold-contents {
    width: 100%;
    text-align: center;
    vertical-align: middle;
    display: table-cell;
}

.comingsoon-header {
    padding:1%;
}


.info-container {
    padding-bottom:2%;
}

._form {
    box-shadow: 0 50px 100px -20px rgb(50 50 93 / 25%), 0 30px 60px -30px rgb(0 0 0 / 30%), 0 -18px 60px -10px rgb(0 0 0 / 3%);
    background:#4d3b8c !important;
    color: #fff !important;
    margin: 0 auto;
    border-radius:5px !important;
    max-width: 750px;
    padding: .01em ;
}



 ._submit  {
    font-family: 'Commissioner', sans-serif !important;
    text-transform: uppercase !important;
    letter-spacing: .1em;
    background: #121940 !important;
    width: 100%;
}


._form_element {
    margin-bottom: 2% !important;
}

._inline-form input, ._form-title {
    font-family: 'Commissioner', sans-serif !important;
    text-transform: uppercase !important;
}

._form-title {
    padding:2%;
    text-align: center!important;
    line-height: 1.5em !important;
    font-size: 16px!important;
    color: #fff !important;
}

._inline-form {
    margin-top: 0 !important;
}

.x0 {
    border-bottom: 3px solid #000;
}



.footer-container {
    bottom: 0;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: relative;
    width: 100%;
    text-align: center;
    font-size: 12px;
    color: #121940;
    margin-top:10%;
}

a {
    color: #121940;    
}
body {
    background:#ffffff;
    margin:0 !important;
    font-family: 'Commissioner', sans-serif;
}

h1 {
    font-size: 3em;
    margin: 0;
    font-weight: 500;
    text-transform: uppercase;
}

h2 {
    font-size: 2em;
    margin: 0;
    font-weight: 700;
    text-transform: uppercase;
    position: relative;
}

h3 {
    font-size: 2.2em;
    margin: .5%;
    font-weight: 900;
    text-transform: uppercase;
    color:#4d3b8c;
}

h4 {
    font-size: 1.5em;
    margin: 5%;
    font-weight: 900;
    text-transform: uppercase;
    color:#121940;
}
