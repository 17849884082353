
.footer-container {
    bottom: 0;
    height: fit-content;
    position: relative;
    width: 100%;
    text-align: center;
    font-size: 12px;
    color: #121940;
    margin-top:10%;
}

a {
    color: #121940;    
}