body {
    background:#ffffff;
    margin:0 !important;
    font-family: 'Commissioner', sans-serif;
}

h1 {
    font-size: 3em;
    margin: 0;
    font-weight: 500;
    text-transform: uppercase;
}

h2 {
    font-size: 2em;
    margin: 0;
    font-weight: 700;
    text-transform: uppercase;
    position: relative;
}

h3 {
    font-size: 2.2em;
    margin: .5%;
    font-weight: 900;
    text-transform: uppercase;
    color:#4d3b8c;
}

h4 {
    font-size: 1.5em;
    margin: 5%;
    font-weight: 900;
    text-transform: uppercase;
    color:#121940;
}